import {
    Absence,
    Employee,
    MonthlyWage,
    PaymentDeduction,
    PaymentDeductionType,
    User,
    WorkNoteInstalment
} from './entities';
import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {WorkNoteDeduction} from './work-note-deduction';

import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {EmployeeMonthlyPaymentRepository} from '../repositories/employee-monthly-payment.repository';
import {MediaObject} from './media-object';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';

import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('employee_monthly_payments')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        absences: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Absence
        },
        paymentDeductions: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: PaymentDeduction
        },
        totalModifiedBy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        workNoteDeductions: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: WorkNoteDeduction
        },
        workNoteInstalments: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: WorkNoteInstalment
        },
        previousEmployeeMonthlyPayment: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeMonthlyPayment
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        },
        monthlyWage: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MonthlyWage
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
@Repository(EmployeeMonthlyPaymentRepository)
export class EmployeeMonthlyPayment {

    protected id: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(MonthlyWage)
    protected monthlyWage: MonthlyWage;

    @AssociationOne(PaymentDeductionType)
    protected totalPaymentDeductionType: PaymentDeductionType;

    @AssociationMany(Absence)
    protected absences: Absence[];

    @AssociationMany(WorkNoteInstalment)
    protected workNoteInstalments: WorkNoteInstalment[];

    @AssociationMany(PaymentDeduction)
    protected paymentDeductions: PaymentDeduction[];

    @AssociationMany(WorkNoteDeduction)
    protected workNoteDeductions: WorkNoteDeduction[];

    @AssociationOne(EmployeeMonthlyPayment)
    protected previousEmployeeMonthlyPayment: EmployeeMonthlyPayment;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected total: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseWorkPositionSalary: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseWorkPositionSalaryAddition: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected differential: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected differentialPercent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected incentive: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected incentivePercent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected plainPersonalLoan: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentCommendationAbsolute: string;

    protected isCalculated: boolean;
    protected isPaid: boolean;
    protected hasPaymentDeductionForMoron: boolean;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected payout: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected payoutRest: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected payoutReal: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected payoutDifference: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected payoutPercent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected salaryFee: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeduction: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionMobilePhoneBill: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionMediGroup: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionIncrease: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionPaymentHoliday: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionReduction: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionSickLeave: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionAbsence: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionAbsenceLeased: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentOvertime: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentCommendation: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected totalDaysAbsent: string;
    protected totalCountAbsent: number;

    protected totalSickLeaveDays: number;
    protected totalSickLeaveAbsences: number;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkViolation: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkViolationPercentage: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseWorkPositionSalaryAdditionPercent: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkNoteWorkViolation: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkNoteWorkViolationPercentage: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkNoteInstalmentWorkViolation: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionTimeWorxViolation: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkNoteWorkViolationSalaryDeductionPercentage: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionWorkNoteWorkViolationSalaryDeduction: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionTimeWorxViolationPercentage: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentCommendationPercentage: string;

    @AssociationOne(User)
    protected totalModifiedBy: User;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected totalModifiedAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected entryDate: Date;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalModifiedPreviousValue: string;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(MediaObject)
    protected medias: any;

    protected isRegulatedMobilePhoneBillPaymentDeduction: boolean;

    protected isConfirmedChange: boolean;
    protected isChangeTotal: boolean;
    protected isChangeBaseWorkPositionSalary: boolean;
    protected isChangeIncentive: boolean;
    protected isChangeIncentivePercent: boolean;
    protected isChangeBaseWorkPositionSalaryAddition: boolean;
    protected isChangeBaseWorkPositionSalaryAdditionPercent: boolean;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionBehaviorWorkplace: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalPaymentDeductionBehaviorWorkplacePercentage: string;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setMonthlyWage(employee: MonthlyWage): this {
        this.monthlyWage = employee;
        return this;
    }

    public getMonthlyWage(): MonthlyWage|undefined {
        return this.monthlyWage;
    }

    public setTotalPaymentDeductionType(totalPaymentDeductionType: PaymentDeductionType): this {
        this.totalPaymentDeductionType = totalPaymentDeductionType;
        return this;
    }

    public getTotalPaymentDeductionType(): PaymentDeductionType|undefined {
        return this.totalPaymentDeductionType;
    }

    public setAbsences(absences: Absence[]): this {
        this.absences = absences;
        return this;
    }

    public getAbsences(): Absence[]|undefined {
        return this.absences;
    }

    public setWorkNoteInstalments(workNoteInstalments: WorkNoteInstalment[]): this {
        this.workNoteInstalments = workNoteInstalments;
        return this;
    }

    public getWorkNoteInstalments(): WorkNoteInstalment[]|undefined {
        return this.workNoteInstalments;
    }

    public setPreviousEmployeeMonthlyPayment(previousEmployeeMonthlyPayment: EmployeeMonthlyPayment): this {
        this.previousEmployeeMonthlyPayment = previousEmployeeMonthlyPayment;
        return this;
    }

    public getPreviousEmployeeMonthlyPayment(): EmployeeMonthlyPayment|undefined {
        return this.previousEmployeeMonthlyPayment;
    }

    public setPaymentDeductions(paymentDeductions: PaymentDeduction[]): this {
        this.paymentDeductions = paymentDeductions;
        return this;
    }

    public getPaymentDeductions(): PaymentDeduction[]|undefined {
        return this.paymentDeductions;
    }

    public setWorkNoteDeductions(workNoteDeductions: WorkNoteDeduction[]): this {
        this.workNoteDeductions = workNoteDeductions;
        return this;
    }

    public getWorkNoteDeductions(): WorkNoteDeduction[]|undefined {
        return this.workNoteDeductions;
    }

    public setTotal(total: string): this {
        this.total = total;
        return this;
    }

    public getTotal(): string|undefined {
        return this.total;
    }

    public setBaseWorkPositionSalary(baseWorkPositionSalary: string): this {
        this.baseWorkPositionSalary = baseWorkPositionSalary;
        return this;
    }

    public getBaseWorkPositionSalary(): string|undefined {
        return this.baseWorkPositionSalary;
    }

    public setBaseWorkPositionSalaryAddition(baseWorkPositionSalaryAddition: string): this {
        this.baseWorkPositionSalaryAddition = baseWorkPositionSalaryAddition;
        return this;
    }

    public getBaseWorkPositionSalaryAddition(): string|undefined {
        return this.baseWorkPositionSalaryAddition;
    }

    public setDifferential(differential: string): this {
        this.differential = differential;
        return this;
    }

    public getDifferential(): string|undefined {
        return this.differential;
    }

    public setDifferentialPercent(differentialPercent: string): this {
        this.differentialPercent = differentialPercent;
        return this;
    }

    public getDifferentialPercent(): string|undefined {
        return this.differentialPercent;
    }

    public setIncentive(incentive: string): this {
        this.incentive = incentive;
        return this;
    }

    public getIncentive(): string|undefined {
        return this.incentive;
    }

    public setIncentivePercent(incentivePercent: string): this {
        this.incentivePercent = incentivePercent;
        return this;
    }

    public getIncentivePercent(): string|undefined {
        return this.incentivePercent;
    }

    public setPlainPersonalLoan(plainPersonalLoan: string): this {
        this.plainPersonalLoan = plainPersonalLoan;
        return this;
    }

    public getPlainPersonalLoan(): string|undefined {
        return this.plainPersonalLoan;
    }
    public setTotalPaymentCommendationAbsolute(totalPaymentCommendationAbsolute: string): this {
        this.totalPaymentCommendationAbsolute = totalPaymentCommendationAbsolute;
        return this;
    }

    public getTotalPaymentCommendationAbsolute(): string|undefined {
        return this.totalPaymentCommendationAbsolute;
    }


    public setPayout(payout: string): this {
        this.payout = payout;
        return this;
    }

    public getPayout(): string|undefined {
        return this.payout;
    }

    public setPayoutRest(payoutRest: string): this {
        this.payoutRest = payoutRest;
        return this;
    }

    public getPayoutRest(): any|undefined {
        return this.payoutRest;
    }

    public setPayoutReal(payoutReal: string): this {
        this.payoutReal = payoutReal;
        return this;
    }

    public getPayoutReal(): any|undefined {
        return this.payoutReal;
    }

    public setPayoutDifference(payoutDifference: string|number|any): this {
        this.payoutDifference = payoutDifference;
        return this;
    }

    public getPayoutDifference(): any|undefined {
        return this.payoutDifference;
    }

    public setPayoutPercent(payoutPercent: string): this {
        this.payoutPercent = payoutPercent;
        return this;
    }

    public getPayoutPercent(): string|undefined {
        return this.payoutPercent;
    }

    public setSalaryFee(salaryFee: string): this {
        this.salaryFee = salaryFee;
        return this;
    }

    public getSalaryFee(): string|undefined {
        return this.salaryFee;
    }

    public setTotalPaymentDeduction(totalPaymentDeduction: string): this {
        this.totalPaymentDeduction = totalPaymentDeduction;
        return this;
    }

    public getTotalPaymentDeduction(): string|undefined {
        return this.totalPaymentDeduction;
    }

    public setTotalPaymentDeductionMobilePhoneBill(totalPaymentDeductionMobilePhoneBill: string): this {
        this.totalPaymentDeductionMobilePhoneBill = totalPaymentDeductionMobilePhoneBill;
        return this;
    }

    public getTotalPaymentDeductionMobilePhoneBill(): string|undefined {
        return this.totalPaymentDeductionMobilePhoneBill;
    }

    public setTotalPaymentDeductionMediGroup(totalPaymentDeductionMediGroup: string): this {
        this.totalPaymentDeductionMediGroup = totalPaymentDeductionMediGroup;
        return this;
    }

    public getTotalPaymentDeductionMediGroup(): string|undefined {
        return this.totalPaymentDeductionMediGroup;
    }

    public setTotalPaymentDeductionIncrease(totalPaymentDeductionIncrease: string): this {
        this.totalPaymentDeductionIncrease = totalPaymentDeductionIncrease;
        return this;
    }

    public getTotalPaymentDeductionIncrease(): string|undefined {
        return this.totalPaymentDeductionIncrease;
    }

    public setTotalPaymentDeductionPaymentHoliday(totalPaymentDeductionPaymentHoliday: string): this {
        this.totalPaymentDeductionPaymentHoliday = totalPaymentDeductionPaymentHoliday;
        return this;
    }

    public getTotalPaymentDeductionPaymentHoliday(): string|undefined {
        return this.totalPaymentDeductionPaymentHoliday;
    }

    public setTotalPaymentDeductionReduction(totalPaymentDeductionReduction: string): this {
        this.totalPaymentDeductionReduction = totalPaymentDeductionReduction;
        return this;
    }

    public getTotalPaymentDeductionReduction(): string|undefined {
        return this.totalPaymentDeductionReduction;
    }

    public setTotalPaymentDeductionSickLeave(totalPaymentDeductionSickLeave: string): this {
        this.totalPaymentDeductionSickLeave = totalPaymentDeductionSickLeave;
        return this;
    }

    public getTotalPaymentDeductionSickLeave(): string|undefined {
        return this.totalPaymentDeductionSickLeave;
    }

    public setTotalPaymentDeductionAbsence(totalPaymentDeductionAbsence: string): this {
        this.totalPaymentDeductionAbsence = totalPaymentDeductionAbsence;
        return this;
    }

    public getTotalPaymentDeductionAbsence(): string|undefined {
        return this.totalPaymentDeductionAbsence;
    }

    public setTotalPaymentDeductionAbsenceLeased(totalPaymentDeductionAbsenceLeased: string): this {
        this.totalPaymentDeductionAbsenceLeased = totalPaymentDeductionAbsenceLeased;
        return this;
    }

    public getTotalPaymentDeductionAbsenceLeased(): string|undefined {
        return this.totalPaymentDeductionAbsenceLeased;
    }

    public setTotalPaymentOvertime(totalPaymentOvertime: string): this {
        this.totalPaymentOvertime = totalPaymentOvertime;
        return this;
    }

    public getTotalPaymentOvertime(): string|undefined {
        return this.totalPaymentOvertime;
    }

    public setTotalPaymentCommendation(totalPaymentCommendation: string): this {
        this.totalPaymentCommendation = totalPaymentCommendation;
        return this;
    }

    public getTotalPaymentCommendation(): string|undefined {
        return this.totalPaymentCommendation;
    }

    public setTotalPaymentCommendationPercentage(totalPaymentCommendationPercentage: string): this {
        this.totalPaymentCommendationPercentage = totalPaymentCommendationPercentage;
        return this;
    }

    public getTotalPaymentCommendationPercentage(): string|undefined {
        return this.totalPaymentCommendationPercentage;
    }

    public setTotalDaysAbsent(totalDaysAbsent: string): this {
        this.totalDaysAbsent = totalDaysAbsent;
        return this;
    }

    public getTotalDaysAbsent(): string|undefined {
        return this.totalDaysAbsent;
    }

    public setTotalCountAbsent(totalCountAbsent: number): this {
        this.totalCountAbsent = totalCountAbsent;
        return this;
    }

    public getTotalCountAbsent(): number|undefined {
        return this.totalCountAbsent;
    }

    public setTotalSickLeaveDays(totalSickLeaveDays: number): this {
        this.totalSickLeaveDays = totalSickLeaveDays;
        return this;
    }

    public getTotalSickLeaveDays(): number|undefined {
        return this.totalSickLeaveDays;
    }

    public setTotalSickLeaveAbsences(totalSickLeaveAbsences: number): this {
        this.totalSickLeaveAbsences = totalSickLeaveAbsences;
        return this;
    }

    public getTotalSickLeaveAbsences(): number|undefined {
        return this.totalSickLeaveAbsences;
    }

    public setTotalPaymentDeductionWorkViolation(totalPaymentDeductionWorkViolation: string): this {
        this.totalPaymentDeductionWorkViolation = totalPaymentDeductionWorkViolation;
        return this;
    }

    public getTotalPaymentDeductionWorkViolation(): string|undefined {
        return this.totalPaymentDeductionWorkViolation;
    }

    public setTotalPaymentDeductionWorkViolationPercentage(totalPaymentDeductionWorkViolationPercentage: string): this {
        this.totalPaymentDeductionWorkViolationPercentage = totalPaymentDeductionWorkViolationPercentage;
        return this;
    }

    public getTotalPaymentDeductionWorkViolationPercentage(): string|undefined {
        return this.totalPaymentDeductionWorkViolationPercentage;
    }

    public setBaseWorkPositionSalaryAdditionPercent(baseWorkPositionSalaryAdditionPercent: string): this {
        this.baseWorkPositionSalaryAdditionPercent = baseWorkPositionSalaryAdditionPercent;
        return this;
    }

    public getBaseWorkPositionSalaryAdditionPercent(): string|undefined {
        return this.baseWorkPositionSalaryAdditionPercent;
    }

    public setTotalPaymentDeductionTimeWorxViolation(totalPaymentDeductionTimeWorxViolation: string): this {
        this.totalPaymentDeductionTimeWorxViolation = totalPaymentDeductionTimeWorxViolation;
        return this;
    }

    public getTotalPaymentDeductionTimeWorxViolation(): string|undefined {
        return this.totalPaymentDeductionTimeWorxViolation;
    }

    public setTotalPaymentDeductionWorkNoteWorkViolation(totalPaymentDeductionWorkNoteWorkViolation: string): this {
        this.totalPaymentDeductionWorkNoteWorkViolation = totalPaymentDeductionWorkNoteWorkViolation;
        return this;
    }

    public getTotalPaymentDeductionWorkNoteWorkViolation(): string|undefined {
        return this.totalPaymentDeductionWorkNoteWorkViolation;
    }

    public setTotalPaymentDeductionWorkNoteWorkViolationPercentage(totalPaymentDeductionWorkNoteWorkViolationPercentage: string): this {
        this.totalPaymentDeductionWorkNoteWorkViolationPercentage = totalPaymentDeductionWorkNoteWorkViolationPercentage;
        return this;
    }

    public getTotalPaymentDeductionWorkNoteWorkViolationPercentage(): string|undefined {
        return this.totalPaymentDeductionWorkNoteWorkViolationPercentage;
    }

    public setTotalPaymentDeductionTimeWorxViolationPercentage(totalPaymentDeductionTimeWorxViolationPercentage: string): this {
        this.totalPaymentDeductionTimeWorxViolationPercentage = totalPaymentDeductionTimeWorxViolationPercentage;
        return this;
    }

    public getTotalPaymentDeductionTimeWorxViolationPercentage(): string|undefined {
        return this.totalPaymentDeductionTimeWorxViolationPercentage;
    }

    public setTotalModifiedBy(totalModifiedBy: User): this {
        this.totalModifiedBy = totalModifiedBy;
        return this;
    }

    public getTotalModifiedBy(): User|undefined {
        return this.totalModifiedBy;
    }

    public setTotalModifiedAt(totalModifiedAt: Date): this {
        this.totalModifiedAt = totalModifiedAt;
        return this;
    }

    public getTotalModifiedAt(): Date|undefined {
        return this.totalModifiedAt;
    }

    public getEntryDate(): Date|undefined {
        return this.entryDate;
    }

    public setEntryDate(entryDate: Date): this {
        this.entryDate = entryDate;
        return this;
    }

    public setTotalModifiedPreviousValue(totalModifiedPreviousValue: string): this {
        this.totalModifiedPreviousValue = totalModifiedPreviousValue;
        return this;
    }

    public getTotalModifiedPreviousValue(): string|undefined {
        return this.totalModifiedPreviousValue;
    }

    public setIsCalculated(isCalculated: boolean): this {
        this.isCalculated = isCalculated;
        return this;
    }

    public getIsCalculated(): boolean|undefined {
        return this.isCalculated;
    }

    public setIsPaid(isPaid: boolean): this {
        this.isPaid = isPaid;
        return this;
    }

    public getIsPaid(): boolean|undefined {
        return this.isPaid;
    }

    public setHasPaymentDeductionForMoron(hasPaymentDeductionForMoron: boolean): this {
        this.hasPaymentDeductionForMoron = hasPaymentDeductionForMoron;
        return this;
    }

    public getHasPaymentDeductionForMoron(): boolean|undefined {
        return this.hasPaymentDeductionForMoron;
    }

    public setIsRegulatedMobilePhoneBillPaymentDeduction(isRegulatedMobilePhoneBillPaymentDeduction: boolean): this {
        this.isRegulatedMobilePhoneBillPaymentDeduction = isRegulatedMobilePhoneBillPaymentDeduction;
        return this;
    }

    public getIsRegulatedMobilePhoneBillPaymentDeduction(): boolean|undefined {
        return this.isRegulatedMobilePhoneBillPaymentDeduction;
    }

    public setIsConfirmedChange(isConfirmedChange: boolean): this {
        this.isConfirmedChange = isConfirmedChange;
        return this;
    }

    public getIsConfirmedChange(): boolean|undefined {
        return this.isConfirmedChange;
    }

    public setIsChangeTotal(isChangeTotal: boolean): this {
        this.isChangeTotal = isChangeTotal;
        return this;
    }

    public getIsChangeTotal(): boolean|undefined {
        return this.isChangeTotal;
    }

    public setIsChangeBaseWorkPositionSalary(isChangeBaseWorkPositionSalary: boolean): this {
        this.isChangeBaseWorkPositionSalary = isChangeBaseWorkPositionSalary;
        return this;
    }

    public getIsChangeBaseWorkPositionSalary(): boolean|undefined {
        return this.isChangeBaseWorkPositionSalary;
    }

    public setIsChangeIncentive(isChangeIncentive: boolean): this {
        this.isChangeIncentive = isChangeIncentive;
        return this;
    }

    public getIsChangeIncentive(): boolean|undefined {
        return this.isChangeIncentive;
    }

    public setIsChangeIncentivePercent(isChangeIncentivePercent: boolean): this {
        this.isChangeIncentivePercent = isChangeIncentivePercent;
        return this;
    }

    public getIsChangeIncentivePercent(): boolean|undefined {
        return this.isChangeIncentivePercent;
    }

    public setIsChangeBaseWorkPositionSalaryAddition(isChangeBaseWorkPositionSalaryAddition: boolean): this {
        this.isChangeBaseWorkPositionSalaryAddition = isChangeBaseWorkPositionSalaryAddition;
        return this;
    }

    public getIsChangeBaseWorkPositionSalaryAddition(): boolean|undefined {
        return this.isChangeBaseWorkPositionSalaryAddition;
    }

    public setIsChangeBaseWorkPositionSalaryAdditionPercent(isChangeBaseWorkPositionSalaryAdditionPercent: boolean): this {
        this.isChangeBaseWorkPositionSalaryAdditionPercent = isChangeBaseWorkPositionSalaryAdditionPercent;
        return this;
    }

    public getIsChangeBaseWorkPositionSalaryAdditionPercent(): boolean|undefined {
        return this.isChangeBaseWorkPositionSalaryAdditionPercent;
    }

    public setMedias(medias: any) {
        this.medias = medias;
        return this;
    }

    public getMedias(): any {
        return this.medias;
    }

    public setTotalPaymentDeductionWorkNoteWorkViolationSalaryDeductionPercentage(value: string): this {
        this.totalPaymentDeductionWorkNoteWorkViolationSalaryDeductionPercentage = value;
        return this;
    }

    public getTotalPaymentDeductionWorkNoteWorkViolationSalaryDeductionPercentage(): string | undefined {
        return this.totalPaymentDeductionWorkNoteWorkViolationSalaryDeductionPercentage;
    }

    public setTotalPaymentDeductionWorkNoteWorkViolationSalaryDeduction(value: string): this {
        this.totalPaymentDeductionWorkNoteWorkViolationSalaryDeduction = value;
        return this;
    }

    public getTotalPaymentDeductionWorkNoteWorkViolationSalaryDeduction(): string | undefined {
        return this.totalPaymentDeductionWorkNoteWorkViolationSalaryDeduction;
    }

    public setTotalPaymentDeductionWorkNoteInstalmentWorkViolation(totalPaymentDeductionWorkNoteInstalmentWorkViolation: string): this {
        this.totalPaymentDeductionWorkNoteInstalmentWorkViolation = totalPaymentDeductionWorkNoteInstalmentWorkViolation;
        return this;
    }

    public getTotalPaymentDeductionWorkNoteInstalmentWorkViolation(): string|undefined {
        return this.totalPaymentDeductionWorkNoteInstalmentWorkViolation;
    }

    public getTotalPaymentDeductionBehaviorWorkplace(): string|undefined {
        return this.totalPaymentDeductionBehaviorWorkplace;
    }

    public setTotalPaymentDeductionBehaviorWorkplace(totalPaymentDeductionBehaviorWorkplace: string): this {
        this.totalPaymentDeductionBehaviorWorkplace = totalPaymentDeductionBehaviorWorkplace;
        return this;
    }

    public getTotalPaymentDeductionBehaviorWorkplacePercentage(): string|undefined {
        return this.totalPaymentDeductionBehaviorWorkplacePercentage;
    }

    public setTotalPaymentDeductionBehaviorWorkplacePercentage(totalPaymentDeductionBehaviorWorkplacePercentage: string): this {
        this.totalPaymentDeductionBehaviorWorkplacePercentage = totalPaymentDeductionBehaviorWorkplacePercentage;
        return this;
    }
}

